<template>
  <b-card
    class="card-verify"
    @click="$router.push({ name: 'master-user-verification' })"
  >
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-grid gap-3">
        <h4 class="mb-25 font-weight-bolder">
          {{ $t('Users to Verify') }}
        </h4>
        <span> {{ $t('Click to open User Verification') }}! </span>
      </div>
      <b-avatar class="" variant="light-info" size="60">
        <feather-icon size="18" icon="UsersIcon" />
        <span class="ml-25" style="font-size: 18px;">
          {{ verifyRequest }}
        </span>
      </b-avatar>
    </div>
  </b-card>
</template>

<script>
import { BCard, BAvatar } from 'bootstrap-vue'

export default {
  components: { BCard, BAvatar },
  computed: {
    verifyRequest() {
      return this.$store.getters['socket/getVerificationRequest']
    },
    // countRequestData() {
    //   return this.$store.state.socket.countRequest
    // },
  },
}
</script>

<style lang="scss" scoped>
.card-verify:hover {
  background-color: #52c3c542;
  cursor: pointer;
}
</style>
