<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col xs="12">
        <card-advance-congratulation />
      </b-col>
    </b-row>
    <b-row cols="12">
      <b-col xs="12" md="6">
        <card-verification />
      </b-col>
      <b-col xs="12" md="6">
        <card-validation />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import CardAdvanceCongratulation from './CardAdvanceCongratulation.vue'
import CardVerification from './CardVerification.vue'
import CardValidation from './CardValidation.vue'

export default {
  name: 'Dashboard',
  components: {
    BRow,
    BCol,
    CardAdvanceCongratulation,
    CardVerification,
    CardValidation,
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    // data
  },
  methods: {},
}
</script>
